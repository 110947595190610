.pageBackground {
  width: 100%;
  height: 100%; /* Adjust height as needed */
  overflow: auto;
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Cover the entire container */
  background-attachment: scroll; /* fixed it was before*/
}

.loginContainer {
  background-color: #2020209c;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.loginText {
  padding-top: 1em;
  font-size: 1.5rem;
  color: white;
}

form {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form input {
  border-radius: 8px;
  border: none;
  font-size: 0.8rem;
  padding: 6px;
  background-color: #202020bb;
  color: white;
}

.errorMessage {
  color: #aa67bb;
  text-align: center;
  font-size: 1.2rem;
}

/* Mobile Media Queries */

/* Responsive scaling */
@media (min-width: 768px) {
  .loginContainer {
    width: 90%;
  }
  .errorMessage {
    font-size: 2.6rem;
  }
  .loginText {
    font-size: 2.9rem;
    margin: auto;
  }
  form input {
    font-size: 2.2rem;
    padding: 10px;
  }
  form {
    margin-top: 1.7rem;
    margin-bottom: 6rem;
    gap: 2rem;
  }
}
@media (min-width: 992px) {
  .loginContainer {
    width: 60%;
  }
}
