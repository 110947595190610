/* Base styles */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 62.5%; /* Set default font size to 10px for easy calculation of rem units */
}

.nav {
  position: fixed;
  background-color: var(--chat-win-color);
  /* background-color: rgba(32, 34, 38, 1); */
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; /* Use rem for padding */
  height: 2.5rem; /* Convert to rem if needed */
  z-index: 1000;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.siteTitle {
  font-size: 1.8rem; /* 16px equivalent */
}
.siteTitle:active {
  transform: scale(var(--active-scale));
}

.nav a,
.nav span {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem; /* Use rem for padding */
  font-size: 1rem; /* 14px equivalent */
  letter-spacing: 0.4rem; /* 4px equivalent */
  white-space: nowrap;
}

.logo {
  max-height: 100%;
  height: 1.3rem; /* Convert to rem if needed */
  padding-right: 1rem; /* Use rem for padding */
}

.dropBtn {
  background-color: var(--text-color);
  color: var(--extra-text-color);
  width: 2rem; /* 45px equivalent */
  height: 2rem; /* 45px equivalent */
  border-radius: 50%;
  border: none;
  font-weight: bold;
  font-size: 1rem; /* 12px equivalent */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropBtn:active {
  transform: scale(var(--active-scale));
}

/* Media queries for larger screens */
@media screen and (min-width: 768px) {
  .nav {
    height: 3.5rem; /* Larger height for navbar */
    padding: 0.5rem; /* Larger padding for navbar */
  }

  .nav a,
  .nav span {
    font-size: 1.4rem; /* Slightly larger font size */
  }

  .logo {
    height: 2rem; /* Larger logo height */
  }
  .dropBtn {
    width: 3rem; /* 45px equivalent */
    height: 3rem; /* 45px equivalent */
  }
}

@media screen and (min-width: 992px) {
  /* Adjustments for larger screens can be added here if needed */
}

@media screen and (min-width: 1200px) {
  /* Adjustments for extra large screens can be added here if needed */
}
