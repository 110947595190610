.chatWindow {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  /*background-color: var(--chat-win-co
  lor);*/
  color: var(--background-color);
}

.header {
  background-color: blue;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
}

.messagesContainer {
  margin-top: 10px;
  flex-grow: 1; /* background-color: var(--chat-win-color) rgb(48, 48, 59)*/
  background-color: var(--chat-win-color);
  overflow-y: auto;
  padding: 10px;
  border-radius: 15px;
}
/* Styling the scrollbar track */
.messagesContainer::-webkit-scrollbar-track {
  background-color: var(--chat-win-color); /* Light gray track */
}

/* Styling the scrollbar thumb */
.messagesContainer::-webkit-scrollbar-thumb {
  background-color: var(--chat-win-color); /* Darker gray thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Styling the scrollbar width */
.messagesContainer::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Optional: styling for hover effect on the thumb */
.messagesContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.footer {
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  gap: 5px;
}

.inputField {
  flex-grow: 1;
  background-color: var(--chat-win-color);
  padding: 10px;
  resize: none;
  overflow-y: auto; /* Ensure scrollbar appears when needed */
  border-radius: 15px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.3;
  height: 5rem; /* Initial height */
  max-height: 7.5em; /* Max height */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.inputField:focus {
  outline: 2px solid #008ece; /* Replace #colorCode with your desired color */
}

/* Styling the scrollbar track */
.inputField::-webkit-scrollbar-track {
  background-color: #333333c2; /* Light gray track */
}

/* Styling the scrollbar thumb */
.inputField::-webkit-scrollbar-thumb {
  background-color: #232323; /* Darker gray thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Styling the scrollbar width */
.inputField::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Optional: styling for hover effect on the thumb */
.inputField::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.sendButton {
  background-color: #202226;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 15px;
}
.sendButton:active {
  transform: scale(0.98);
}
.sendButtonInactive {
  background-color: #ccc; /* Gray color for inactive state */
  color: #323232; /* Darker text color for better readability on gray background */
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
  border-radius: 15px;
}

.chatbotMessage,
.userMessage {
  display: flex;
  flex-direction: row;
  color: var(--text-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: 15px;
  font-size: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.chatbotMessage {
  background-color: var(--chatbot-message); /*rgb(77, 77, 92)
  */
}

.userMessage {
  background-color: var(--user-message) /*rgb(37, 37, 46)*/;
  border: solid;
  border-color: #008ece;
}
.message {
  padding-left: 15px;
  padding-top: 10px;
}

.dots {
  padding-left: 15px;
  padding-top: 10px;
}

.dots span {
  font-size: 2rem;
  opacity: 0;
  animation: blink 1.5s infinite;
}

.dots span:nth-child(1) {
  animation-delay: 0s;
}

.dots span:nth-child(2) {
  animation-delay: 0.5s;
}

.dots span:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.avatar {
  height: 2.5rem;
  width: 2.5rem;
}

.saveButton {
  color: white;
  font-weight: bold;
  background-color: #006c67;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 15px;
}
.saveButton:active {
  transform: scale(0.98); /* press down effect */
}
.saveButtonInactive {
  background-color: #ccc; /* Gray color for inactive state */
  color: #666; /* Darker text color for better readability on gray background */
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
  border-radius: 15px;
}

.retryBtn {
  display: block; /* Allows margin auto to work */
  margin-left: auto;
  margin-right: auto;
  color: rgb(255, 255, 255);
  font-weight: bold;
  background-color: rgb(226, 62, 62);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: rgb(199, 42, 42);
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 15px;
}

.link {
  color: var(--link); /* Default color */
  text-decoration: none; /* Remove underline */
}

.link:hover {
  color: var(--text-color-error); /* Color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.parentContainer {
  text-align: center;
  width: 100%; /* Or specific width */
}

.chatbotTitle {
  color: var(--text-color);
  font-size: 1rem;
}
/* Responsive scaling */
@media (min-width: 768px) {
  .chatWindow {
    padding-top: 0px;
  }
  .inputField {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .chatbotMessage,
  .userMessage {
    display: flex;
    flex-direction: row;
    color: var(--text-color);
    padding: 10px;
    margin: 20px 0;
    border-radius: 15px;
    font-size: 1.3rem;
  }

  .avatar {
    height: 4rem;
    width: 4rem;
  }

  .message {
    padding-left: 15px;
    padding-top: 18px;
  }
  .dots {
    padding-left: 15px;
    padding-top: 18px;
  }
  .chatbotTitle {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
}
