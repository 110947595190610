.panel {
  display: flex;
  flex-direction: column; /* Align children vertically */
  background-color: var(--chat-win-color);
  margin-right: 20px;
  /* background-color: var(--panel-color);  rgb(29, 29, 29)*/
  text-align: center;
  color: var(--text-color) /*white*/;
}

.containerSmall {
  padding-top: 15px;
  display: flex;
  flex-direction: row; /* Align children vertically */
}

.conversationsList {
  overflow-y: auto;
  margin-bottom: 14%;
}
.conversation_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  /* Additional styles */
}

.conversationTitle {
  font-size: 0.9rem;
  color: var(--text-color) /*white*/;
  text-decoration: none;
}
.delete_button {
  color: var(--text-color) /*white*/;
  background: none;
  border: none;
  cursor: pointer;
  /* Additional styles, e.g., for size and padding */
}

.link {
  position: relative; /* Needed to position the pseudo-element correctly */
  width: 90%;
  margin-left: 2%;
  margin-bottom: 2%;
  margin-top: 10%;
  font-size: 0.9rem;
  color: var(--text-color); /*white*/
  text-decoration: none;
  border: 1px solid /*white*/ #3b3838;
  padding: 7px;
  border-radius: 12px;
  display: flex; /* Establish flex container */
  justify-content: space-between; /* Space out children */
  align-items: center; /* Align items vertically */
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  margin-top: 10%;
  margin-left: 2%;
  margin-bottom: 2%;
}

.textContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%; /* Adjust as needed */
  display: inline-block; /* or block */
}
.link:active {
  transform: scale(0.9); /* Scales down to 90% when clicked */
  transition: transform 0.1s; /* Smooth transition for the effect */
}

.link:hover {
  background-color: var(--chat-win-color); /* Example style */
  color: var(--text-color); /* Example style */
}

.activeLink {
  /* Your styles for the active link */
  background-color: var(--selected-conversation);
  color: var(--text-color) /*white*/; /* Example style */
}

.line {
  width: 90%;
  padding-left: 20px;
  border: 0;
  height: 2px;
  background-color: var(--text-color) /*white*/;
}

.name {
  font-weight: bolder;
  padding: 10px;
  font-size: 1rem;
  display: flex;
}

.arrowBack {
  position: absolute; /* Changed from default */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  padding: 10px;
  font-size: 18px;
  color: var(--text-color); /*white*/
}
.arrowBack:active {
  transform: scale(0.9); /* Scales down to 90% when clicked */
  transition: transform 0.1s; /* Smooth transition for the effect */
}

/* Styles for the scrollbar track (background) */
::-webkit-scrollbar {
  width: 12px; /* Adjust width */
}

/* Styles for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Optional: creates a gap between thumb and track */
  background-clip: content-box; /* Ensures the border doesn't overlap the background color */
}

/* Styling the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

/* Optional: Remove scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Styles for the scrollbar track (background) */
::-webkit-scrollbar {
  width: 12px; /* Adjust width */
}

/* Styles for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Optional: creates a gap between thumb and track */
  background-clip: content-box; /* Ensures the border doesn't overlap the background color */
}

/* Styling the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color-hoover); /* Color on hover */
}

/* Optional: Remove scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Firefox scrollbar styles */
* {
  scrollbar-color: var(--scrollbar-color); /* Thumb and track color */
}

/* Responsive scaling */
@media (min-width: 768px) {
  .conversationTitle {
    font-size: 18px;
  }
  .link {
    width: 300px;
    margin: 4%;
    margin-left: 2%;
    font-size: 18px;
    padding: 10px;
  }

  .icon {
    margin-top: 0%;
    margin-left: 0%;
    margin-bottom: 0%;
  }

  .name {
    font-weight: bolder;
    padding: 10px;
    font-size: 1.3rem;
  }

  .containerSmall {
    padding-top: 0px;
  }
}
@media (min-width: 992px) {
}
