/* ChatbotDashboard.module.css */
.container {
  background-color: var(--background-color);
  min-height: calc(100vh - 3.5rem);
  padding: 20px;
  box-sizing: border-box;
}

.pageTitle {
  color: var(--text-color);
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
}

.titleLine {
  height: 2px;
  background-color: var(--text-color);
  margin-bottom: 20px;
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.icon {
  color: var(--text-color);
}
/* Responsive scaling */
@media (min-width: 768px) {
  .pageTitle {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .pageTitle {
    font-size: 1.3rem;
  }
}
